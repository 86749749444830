@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Maven Pro", sans-serif;
}

nav {
  transition: transform 0.3s ease-in-out;
}

button {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.backdrop {
  transition: opacity 0.3s ease-in-out;
}

.active-link {
  border-bottom: 2px solid #f46524; /* Highlight color for active link */
  color: #f46524; /* Text color for active link */
  transition: border-color 0.3s, color 0.3s;
}

.nav a:hover,
.nav .active-link {
  border-bottom: 2px solid #f46524; /* Highlight color for hover and active */
}
